<template>
  <action-dispatcher action="providers/getProviders" :parameters="$route.query">
    <div class="providers-view">
      <carousel-providers
        v-if="bannersProviders && bannersProviders.length"
        :banners="bannersProviders"
      />
      <div class="container my-4">
        <h2 class="page-title text-center text-lg-left text-uppercase">
          Fornecedores
        </h2>
        <p class="page-description text-center text-lg-left text-light mb-5">
          Encontre os nossos parceiros com ofertas únicas para o seu projeto.
        </p>
        <div class="row mb-5 align-items-center">
          <div class="col-lg-5 mb-lg-0 mb-4">
            <search-input
              id="search"
              v-model="paramsFilter.query"
              placeholder="Pesquisar"
              class="mr-2"
              label=""
            />
          </div>
          <div class="col-lg-7 mt-lg-n1">
            <div class="row mt-lg-n4">
              <div class="col-lg-4">
                <select-dropdown
                  full-object
                  :veryShortEllipsis="false"
                  prop-name="name"
                  label="Categorias"
                  labelColor="light"
                  :options="categories"
                  v-model="selected_categories"
                  @submit="submitCategoriesFilter"
                />
              </div>
              <div class="col-lg-4 my-lg-0 my-4">
                <select-dropdown
                  full-object
                  veryShortEllipsis
                  :selectOne="true"
                  label="Estado"
                  labelColor="light"
                  :options="districts"
                  v-model="selected_state"
                  @submit="submitStates"
                />
              </div>
              <div class="col-lg-4">
                <select-dropdown
                  full-object
                  :veryShortEllipsis="true"
                  prop-name="name"
                  label="Cidade"
                  labelColor="light"
                  :options="citiess"
                  v-model="selected_cities"
                  @submit="submitCities"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-lg-end mt-n5">
          <div class="col-lg-3 d-flex justify-content-lg-end px-0">
            <order-by
              v-model="paramsFilter.order"
              class="px-0 mr-lg-n4 ml-n4 ml-lg-n0"
            />
            <button class="btn btn-sm text-primary mr-3" @click="clearFilters">
              <small>Limpar</small>
            </button>
          </div>
        </div>
        <div v-if="selected_categories.length" class="mb-4">
          <h3 class="text-uppercase mb-3">
            Resultados para "{{
              selected_categories.map(id => id_name_map[id]).join(", ")
            }}"
          </h3>
        </div>
        <div v-if="providers.length" class="row mt-4">
          <div
            v-for="provider in providers"
            :key="`provider-${provider.id}`"
            class="col-md-6 col-lg-3 d-flex mb-5"
          >
            <card-providers :provider="provider" />
          </div>
        </div>
        <div v-else class="void">
          <em>Nenhum fornecedor encontrado</em>
        </div>
        <div
          v-if="pagination.total > 1"
          class="d-flex w-100 justify-content-center"
        >
          <paginator
            v-model="paramsFilter.page"
            :pagination="pagination"
            class="mb-5"
          />
        </div>
      </div>
    </div>
  </action-dispatcher>
</template>
<script>
import OrderBy from "@/components/filters/OrderBy.vue";
import CarouselProviders from "@/components/providers/CarouselProviders.vue";
import CardProviders from "@/components/providers/CardProviders.vue";
import { normalizeText, clearObject } from "@/functions/helpers";
import { paramsMixin } from "@/mixins";
import { mapState } from "vuex";
export default {
  mixins: [paramsMixin],
  components: {
    CarouselProviders,
    OrderBy,
    CardProviders
  },
  data() {
    const selected_categories = this.$route.query?.related_product
      ? this.$route.query.related_product.split(",")
      : [];
    const selected_state = this.$route.query.state
      ? JSON.parse(localStorage.getItem("stateF"))
      : [];
    const selected_cities = this.$route.query.city
      ? JSON.parse(localStorage.getItem("cityF"))
      : [];
    return {
      search: "",
      selected_state,
      selected_cities,
      selected_categories,
      timeout: 0,
      paramsFilter: {
        related_product: "",
        order: "random",
        query: "",
        page: 1,
        city: "",
        state: "",
        ...this.$route.query
      }
    };
  },
  mounted() {
    this.$store.dispatch("districts/getDistricts");
    this.$store.dispatch("banners/getProviders");
    this.$store.dispatch("providers/getCategories");
  },
  watch: {
    paramsFilter: {
      deep: true,
      handler(newVal) {
        if (newVal.query != "") {
          this.timeout = 3000;
        }
        setTimeout(() => {
          this.$router.push({
            ...this.$route,
            query: {
              ...clearObject(this.$route.query),
              ...clearObject(newVal)
            }
          });
        }, this.timeout);
      }
    },
    categories(newV) {
      this.updateNameMap();
    }
  },
  computed: {
    ...mapState("districts", ["districts", "cities"]),
    ...mapState("banners", ["bannersProviders"]),
    ...mapState("providers", ["providers", "categories", "pagination"]),
    citiess() {
      if (this.$route.query.state) {
        return this.cities;
      } else {
        return [];
      }
    }
  },
  methods: {
    submitStates(choosed_district) {
      //this.paramsFilter.state = choosed_district[0].sigla;
      if (choosed_district) {
        localStorage.removeItem("stateF");
        this.selected_state.push(choosed_district[0].id);
        localStorage.setItem("stateF", JSON.stringify(this.selected_state));
        this.$router.push({
          ...this.$route,
          query: {
            ...clearObject(this.$route.query),
            state: choosed_district[0].sigla
          }
        });
        this.$store.dispatch("districts/getCities", choosed_district[0].id);
      }
    },
    submitCities(choosed_cities) {
      if (choosed_cities) {
        localStorage.removeItem("cityF");
        const citiesNames = choosed_cities.map(cit => cit.name).join(",");
        const citiesID = choosed_cities.map(cit => cit.id);
        localStorage.setItem("cityF", JSON.stringify(citiesID));
        this.$router.push({
          ...this.$route,
          query: {
            ...clearObject(this.$route.query),
            city: citiesNames
          }
        });
      }
    },
    submitCategoriesFilter(choosed_categories) {
      if (choosed_categories) {
        const ids = choosed_categories.map(cat => cat.id);
        this.selected_categories = ids;
        this.$router.push({
          ...this.$route,
          query: {
            ...clearObject(this.$route.query),
            related_product: ids.join(",")
          }
        });
      }
    },
    clearFilters() {
      this.$router.push({
        ...this.$route,
        query: null
      });
      localStorage.removeItem("stateF");
      localStorage.removeItem("cityF");
    }
  },
  clearState() {
    this.$router.push({
      ...this.$route,
      state: null
    });
    localStorage.removeItem("stateF");
  }
};
</script>
<style lang="scss" scoped>
.providers-view {
  .page-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #f5f5f5;
  }
  .page-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.015em;
    text-transform: uppercase;
    color: #f5f5f5;
  }

  .void {
    min-height: 20vh;
  }
}
</style>
