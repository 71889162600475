<template>
  <div class="carousel-providers-component w-100">
    <carousel
      class="position-relative w-100"
      :autoplayHoverPause="true"
      :perPage="1"
      :autoplay="true"
      :paginationEnabled="true"
      paginationActiveColor="#fbfbfb"
      paginationColor="#cfcfcf"
      :paginationSize="8"
      :paginationPadding="5"
      :autoplayTimeout="3500"
      :loop="true"
    >
      <slide v-for="i in banners" :key="`banner-${i.id}`" class="w-100">
        <!-- <img
          class="img-fluid d-none d-lg-block w-100"
          :src="i.banner_image"
          alt="Banner teste"
        /> -->
        <a :href="i.link" target="_blank">
          <div
            :style="`background-image: url(${i.banner_image});`"
            class="cover"
          ></div>
        </a>
      </slide>
    </carousel>
  </div>
</template>
<script>
export default {
  props: {
    banners: {
      type: Array,
      default: () => []
    }
  }
};
</script>
<style lang="scss" scoped>
.carousel-providers-component {
  width: 100vw !important;

  .cover {
    height: 0;
    width: 100%;
    padding-bottom: 30%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
::v-deep .VueCarousel-dot-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
</style>
