<template>
  <div class="card-providers-component d-flex w-100">
    <card shape="small" class="w-100">
      <div
        :style="`background-image:url(${provider.cover});`"
        class="custom-card-header w-100"
      ></div>
      <div class="custom-card-body p-2 w-100 h-100">
        <router-link
          class="text-decoration-none w-100 mt-2"
          :to="{ name: 'provider', params: { id: provider.id } }"
        >
          <div class="d-flex mt-n2 mb-3">
            <img
              width="86"
              height="86"
              class="emblem-img mt-n4 mr-3"
              :src="provider.photo_perfil"
              :alt="provider.name"
            />
            <div>
              <h6>{{ provider.title_page }}</h6>
              <p>{{ provider.subtitle_page }}</p>
            </div>
          </div>
          <p class="text-center description">
            {{ provider?.product[0]?.description }}
          </p>
          <h5 class="price text-center mb-3">
            {{ provider?.product[0]?.offer_price }}
          </h5>
        </router-link>
      </div>
    </card>
  </div>
</template>
<script>
export default {
  props: {
    provider: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style scopped lang="scss">
.card-providers-component {
  min-width: 279px;
  .custom-card-header {
    height: 0px;
    padding-bottom: 60%;
    width: 100%;
    background-color: #737373;
    background-size: cover;
    background-position: center;
  }
  .custom-card-body {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h6 {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #0a0a0a;
    }
    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #595959;

      &.description {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.015em;
        color: #0a0a0a;
      }
    }

    .price {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #0a0a0a;
    }
  }

  .emblem-img {
    background: #fff;
    box-shadow: 0px 0px 3px 0px #737373;
  }
}
</style>
